<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <b>Daftar Drafting Ditolak</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Datatables
              @doDetail="doDetail"
              @doEdit="doEdit"
              :action="true"
              :trigger="{ status: 2 }"
              :columnFiltering="true"
              action-data="row_number"
              :url="'drafting'"
              :dtfields="headers"
            >
              <template>
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    data-action="doDetail"
                    class="btn btn-success"
                  >
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <!-- <button type="button" data-action="doEdit" class="btn btn-warning"><i class="mdi mdi-grease-pencil"></i></button> -->
                </div>
              </template>
            </Datatables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      headers: {
        no_opd: {
          label: "Nomor Surat",
          data: "no_opd",
        },
        subject: {
          label: "Perihal",
          data: "subject",
        },
        document_date: {
          label: "Tanggal",
          data: "document_date",
          render: function (data) {
            return new Date(data).toDateString();
          },
        },
        document_number: {
          label: "Nomor Surat",
          data: "document_number",
        },
        sender_id: {
          label: "Pembuat",
          data: "sender.name",
        },
        final_approved: {
          label: "Status",
          data: "final_approvement.name",
          render: function (data) {
            return _this.$t(data);
          },
        },
      },
    };
  },
  computed: {},
  mounted() {
    const state = {
      loaded: false,
      isAdd: false,
      isEdit: false,
      isDetail: false,
    };
    this.$store.commit("drafting_declined/STATE", state);
  },
  methods: {
    doDetail(val) {
      // this.$store.dispatch("drafting_declined/onDetail", val);
      const idsurat = val.id;
      let route = this.$router.resolve({ path: "/drafting-declined/detail" });
      let route1 = `${route.href}/${idsurat}`;
      window.open(route1, "_blank");
    },
    doEdit(val) {
      this.$store.dispatch("drafting_declined/onEdit", val);
    },
  },
};
</script>
